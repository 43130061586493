export const getUserAvatarName = (userName: string | undefined): string => {
    if (userName) {
        const splitUserName = userName.split(' ');
        if (splitUserName.length > 1) {
            return String(userName.split(' ')[0][0] ?? '') + String(userName.split(' ')[splitUserName.length - 1][0] ?? '');
        } else if (userName.length >= 2) {
            return userName[0] + userName[1];
        } else {
            return userName[0];
        }
    } else {
        return '';
    }
};
