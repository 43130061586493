import React from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';
import {Translation} from 'react-i18next';
import {globalConfig} from '@/Helpers/globalConfig';

type ErrorState = {
    hasError: boolean;
};

export default class BaseErrorBoundary extends React.Component {
    constructor(props: {children: React.ReactNode}) {
        super(props);
        this.state = {hasError: false};
    }

    state: ErrorState = {
        hasError: false,
    };

    static getDerivedStateFromError(): ErrorState {
        return {hasError: true};
    }

    componentDidCatch(error: any, errorInfo: any): void {
        //TODO: also send error to server
        if (globalConfig.system !== 'development') {
            void fetch(window.location.origin + '/version', {method: 'GET'})
                .then(async result => {
                    const version = await result.text();
                    if (version.indexOf(VERSION) === -1) {
                        console.log(
                            'currently running version is not the same as deployed one. refreshing...',
                            'deployed',
                            version,
                            'running',
                            VERSION,
                        );
                        window.location.reload();
                    }
                })
                .catch((e: Error) => console.error('Checking version after error failed', e));
        } else {
            console.log('Cannot compare version for local env.');
        }

        console.error(error, errorInfo);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <Translation ns={'error'}>
                    {t => (
                        <Card>
                            <CardHeader title={t('heading')}/>
                            <CardContent>
                                <p>
                                    {t('description')}
                                    <br/>
                                    {t('action')}
                                    <br/>
                                    {t('persisting')}
                                </p>
                                <p>{t('calming_info')}</p>
                            </CardContent>
                        </Card>
                    )}
                </Translation>
            );
        }

        return this.props.children;
    }
}
