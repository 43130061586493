import React from 'react';
import {Alert, AlertColor, Button, Card, CardContent, CardHeader, Grid, TextField} from '@mui/material';
import styled from 'styled-components';
import {Trans, useTranslation} from 'react-i18next';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {FormiflyForm} from 'formifly';
import {BankAccount, getBankAccountEditShape} from '@/Areas/BankAccount/Data/BankAccount';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import withLoginRequirement from '@/Components/withLoginRequirement';

const BankAccountEdit = (): React.JSX.Element => {
    const {t} = useTranslation(['bankAccount', 'common', 'formifly', 'error']);
    const {id} = useParams();
    const navigate = useNavigate();

    const [bankAccount, setBankAccount] = React.useState<BankAccount>();
    const [error, setError] = React.useState(false);
    const bankAccountShape = getBankAccountEditShape();
    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});

    React.useEffect(() => {
        callWithJwt<BankAccount>('/accounts/' + String(id), 'GET')
            .then((response) => {
                if (response.data) {
                    setBankAccount(response.data);
                    return Promise.resolve();
                } else {
                    return Promise.reject(response);
                }
            })
            .catch((reason) => {
                console.error('Failed to fetch bank account', reason);
                if (reason?.data?.error?.code === 'not_found') {
                    setAlert({show: true, message: 'error.not_found', severity: 'error'});
                }
                setError(true);
            });
    }, []);

    const handleSubmit = (values: any): void => {
        callWithJwt('/accounts/' + String(id), 'PATCH', values)
            .then(() => {
                navigate('/account/payment');
            })
            .catch((reason) => {
                setAlert({show: true, message: 'error.unexpected', severity: 'error'});
                console.log(reason.data.error);
            });
    };

    if (error) {
        return <Card>
            <CardHeader title={t('error:heading')}/>
            <CardContent>
                <p>{t('bankAccount:fetch_failed')}</p>
            </CardContent>
        </Card>;
    }

    if (bankAccount === undefined) {
        return <CenteredCircularProgress/>;
    }

    return <>
        <Card>
            <CardHeader title={t('edit')}/>
            <CardContent>
                {alert.show && <PaddedGrid item xs={12}>
                    <Alert severity={alert.severity as AlertColor}>
                        <Trans t={t} ns="bankAccount">{alert.message}</Trans>
                    </Alert>
                </PaddedGrid>}
                <p>
                    {t('edit_info')}
                </p>
                <FormiflyForm shape={bankAccountShape}
                              defaultValues={bankAccount as any}
                              onSubmit={handleSubmit as any}
                              t={t as any}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField name="identifier"
                                       label={t('bankAccount:iban')}
                                       value={bankAccount.identifier}
                                       disabled
                                       fullWidth={true}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="name" label={t('bankAccount:name')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="internal_comment" label={t('bankAccount:internal_comment')}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Button color="primary" type="submit" variant="contained" fullWidth={true}>
                                {t('bankAccount:save')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button to="/account/payment" component={Link} variant="contained" color="error" fullWidth={true}>
                                {t('common:cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </FormiflyForm>
            </CardContent>
        </Card>
    </>;
};

const PaddedGrid = styled(Grid)`
    padding-bottom: 16px;
`;

export default withLoginRequirement(BankAccountEdit);
