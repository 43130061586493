import React from 'react';
import {Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {AddRounded, EditRounded} from '@mui/icons-material';
import {IconButtonWithTooltip, LowercaseButton} from '@common/butterfly-shared-react-library';
import withLoginRequirement from '@/Components/withLoginRequirement';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import type {BankAccount} from '@/Areas/BankAccount/Data/BankAccount';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import TableRowHeading from '@/Components/Tables/TableRowHeading';
import ConfirmBankAccountDeletion from '@/Areas/BankAccount/Components/ConfirmBankAccountDeletion';

const BankAccountOverview = (): React.JSX.Element => {
    const [bankAccounts, setBankAccounts] = React.useState<BankAccount[]>();
    const [accountToDelete, setAccountToDelete] = React.useState<BankAccount>();
    const [deletionError, setDeletionError] = React.useState<string>();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    const {t} = useTranslation(['common', 'bankAccount', 'error']);

    React.useEffect(() => {
        callWithJwt<BankAccount[]>('/accounts', 'GET')
            .then((response) => {
                if (response.data) {
                    setBankAccounts(response.data);
                    return Promise.resolve();
                } else {
                    return Promise.reject(response);
                }
            })
            .catch((reason) => {
                console.error('Failed to fetch bank accounts', reason);
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    const handleOpenDeleteConfirm = (deleteWhich: BankAccount): void => {
        setAccountToDelete(deleteWhich);
    };

    const handleCloseDeleteConfirm = (): void => {
        setAccountToDelete(undefined);
        setDeletionError(undefined);
    };

    const handleAccountDeletion = (): void => {
        callWithJwt('/accounts/' + String(accountToDelete!.id), 'DELETE')
            .then(() => {
                setBankAccounts((oldBankAccounts) => {
                    return oldBankAccounts!.filter((bankAccount: BankAccount) => bankAccount.id !== accountToDelete!.id);
                });
                handleCloseDeleteConfirm();
            })
            .catch((reason) => {
                console.error('Failed to delete bank account', reason);
                setDeletionError('deletion_failed');
            });
    };

    if (loading) {
        return <CenteredCircularProgress/>;
    }

    if (error) {
        return <Card>
            <CardHeader title={t('error:heading')}/>
            <CardContent>
                <p>{t('bankAccount:fetch_failed')}</p>
            </CardContent>
        </Card>;
    }

    return <>
        <Card>
            <CardHeader title={t('bankAccount:heading')}
                        action={<IconButtonWithTooltip label={t('bankAccount:add')} component={Link}
                                                       to={'/account/payment/add'}>
                            <AddRounded/>
                        </IconButtonWithTooltip>}/>
            <CardContent>
                {bankAccounts!.length === 0
                    ? <p>{t('bankAccount:none_found')}</p>
                    : <Grid container spacing={2}>
                        {bankAccounts!.map((bankAccount, index) => <Grid item key={index}>
                            <Card>
                                <LessPaddedCardHeader
                                    title={bankAccount.name === '' ? t('bankAccount:bank_account') : bankAccount.name}
                                    action={<IconButtonWithTooltip label={t('bankAccount:edit')}
                                                                   component={Link}
                                                                   to={'/account/payment/' + String(bankAccount.id) + '/edit'}>
                                        <EditRounded/>
                                    </IconButtonWithTooltip>}/>
                                <CardContent>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableRowHeading>{t('common:iban')}:</TableRowHeading>
                                                <TableCell>{bankAccount.identifier}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableRowHeading>{t('bankAccount:bic')}:</TableRowHeading>
                                                <TableCell>{bankAccount.bic}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableRowHeading>{t('common:internal_comment')}:</TableRowHeading>
                                                <TableCell>{bankAccount.internal_comment}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <LowercaseButton color="error"
                                                     variant="contained"
                                                     fullWidth
                                                     onClick={() => handleOpenDeleteConfirm(bankAccount)}>
                                        {t('bankAccount:delete')}
                                    </LowercaseButton>
                                </CardContent>
                            </Card>
                        </Grid>)}
                    </Grid>}
            </CardContent>
        </Card>
        <ConfirmBankAccountDeletion t={t}
                                    error={deletionError}
                                    onClose={handleCloseDeleteConfirm}
                                    onDelete={handleAccountDeletion}
                                    bankAccount={accountToDelete}/>
    </>;
};

const LessPaddedCardHeader = styled(CardHeader)`
    padding: 0.25rem;

    & span {
        font-size: 1.1rem;
    }
`;

export default withLoginRequirement(BankAccountOverview);
